import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

const Header = ({isAuthenticated}) => {
  const [print, setprint] = useState(false);
  const [addrressInfo, setAddrressInfo] = useState("");
  const [emailInfo, setEmailInfo] = useState("");
  const [phoneInfo, setPhoneInfo] = useState("");
  const [logoURL, setLogoURL] = useState("");


  // vs-body-visible
  async function fetchData() {
    try {
      const response = await fetch(`${process.env.REACT_APP_STRAPI_API_URL}/api/footer-navbars?populate=*`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setAddrressInfo(data.data[0].attributes.ContactInfo[0].Value);
      setEmailInfo(data.data[0].attributes.ContactInfo[1].Value);
      setPhoneInfo(data.data[0].attributes.ContactInfo[2].Value);
      setLogoURL(data.data[0].attributes.Logo.data.attributes.url);
    } catch (error) {
      console.error('There was a problem fetching the data:', error);
    }
  }
  
  fetchData();
  const navigate = useNavigate()

  useEffect(() => {
    let username = sessionStorage.getItem("phone");
    if (username === "" || username === null) {
      setprint(true);
    }
  }, []);

  return (
    <>
      <header class="tj-header">
        {/* <!--Header Content Start--> */}
        <div class="container">
          <div class="row">
            {/* <!--Toprow Content Start--> */}
            <div class="col-md-3 col-sm-4 col-xs-12 main-logo">
              <div class="tj-logo">
                <img
                className="cursor-pointer"
                onClick={()=>{
                  navigate("/")
                }}
                  style={{
                    height: "100px",
                    margin: "auto",
                    marginTop: "20px",
                    display: "block",
                  }}
                  alt="logo"
                  src={`${process.env.REACT_APP_STRAPI_API_URL}${logoURL}`}

                />
              </div>
              {/* <!--Logo End--> */}
            </div>
            {/* <div class="col-md-3 col-sm-4 col-xs-12">
              <div class="info_box">
                <i class="fa fa-home"></i>
                <div class="info_text add-format">
                  <span class="info_title ">Address</span>
                  <span>{process.env.REACT_APP_BUSINESS_ADDRESS}</span>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-12">
              <div class="info_box">
                <i class="fa fa-envelope"></i>
                <div class="info_text">
                  <span class="info_title">Email Us</span>

                  <span>
                    <a href={`mailto:${process.env.REACT_APP_EMAIL}`}>
                      {process.env.REACT_APP_EMAIL}
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-xs-12">
              <div class="phone_info">
                <div class="phone_icon">
                  <i class="fas fa-phone-volume"></i>
                </div>
                <div class="phone_text">
                  <span>
                    <a
                      href={`tel:${process.env.REACT_APP_BUSINESS_PHONE_NUMBER.replace(
                        /\s/g,
                        ""
                      )}`}
                    >
                      {process.env.REACT_APP_BUSINESS_PHONE_NUMBER}
                    </a>
                  </span>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        <div class="tj-nav-row">
          <div class="container">
            <div class="row">
              <div class="tj-nav-holder">
                <nav class="navbar navbar-default">
                <div class="navbar-header" style={{ display: 'flex', alignItems: 'center' }}>
                <div class="logo-mobile" style={{ width: "40%", margin: 'auto', marginTop:0 , marginBottom:0,display: 'flex', justifyContent: 'center' , paddingLeft:"18%"}}>
    <img src="images/logo-mobile.png" height="40px" width="150px"/>
</div>


    <button
        type="button"
        class="navbar-toggle collapsed"
        data-toggle="collapse"
        data-target="#tj-navbar-collapse"
        aria-expanded="false"
    >
        <span class="sr-only">Menu</span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
        <span class="icon-bar"></span>
    </button>
</div>

                   {/* <div class="navbar-header">
                     <div style={{border:"2px solid blue", width:"60%" }}>
                   logo
                     </div>
                    <button
                      type="button"
                      class="navbar-toggle collapsed"
                      data-toggle="collapse"
                      data-target="#tj-navbar-collapse"
                      aria-expanded="false"
                    >
                      <span class="sr-only">Menu</span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                      <span class="icon-bar"></span>
                    </button>
                  </div> */}
                  {/* <!-- Navigation Content Start --> */}
                  <div class="collapse navbar-collapse" id="tj-navbar-collapse">
                    <ul class="nav navbar-nav">
                      <li class="dropdown">
                        {" "}
                        <Link to="/">Home</Link>
                      </li>
                      <li>
                        <Link to="/about-us">About Us</Link>
                      </li>
                      <li>
                        <Link to="/services">Services</Link>
                      </li>
                      <li>
                        <Link to="/contact-us">Contact Us</Link>
                      </li>

                      {!isAuthenticated ? (
                        <>
                          <li>
                            <Link to="/login">Login</Link>
                          </li>
                        </>
                      ) : (
                        <li>
                          <Link to="/profile">My Account</Link>
                        </li>
                      )}
                      <li></li>
                    </ul>
                  </div>
                  {/* <!-- Navigation Content Start --> */}
                </nav>
                {/* <!--Menu Holder End--> */}
                <div class="book_btn">
                  <Link to="/booknow">
                    Book Now{" "}
                    <i class="fa fa-arrow-circle-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
              {/*<!--Nav Holder End--> */}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
