import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Header from './common/Header';
import Footer from './common/Footer';
import MainRoutes from './routes/MainRoutes';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(sessionStorage.getItem("auth") ? true : false);

  useEffect(()=>{
    setIsAuthenticated(sessionStorage.getItem("auth") ? true:false)
  },[])
  return (
    <>
      <Header isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} /> {/* Pass setIsAuthenticated to the Header component */}
      <div className="App">
          <MainRoutes setIsAuthenticated={setIsAuthenticated} /> {/* Pass setIsAuthenticated to the MainRoutes component */}
        <ToastContainer />
      </div>
      <Footer /> {/* Include the Footer component */}
    </>
  );
}

export default App;
